<template>
    <div class="bg-white shadow rounded px-2 pt-3 mt-3 pb-4.5 mb-4">
        <p class="font-barlow font-bold text-mdl text-grey-light mb-3">
            Add Participants to Take the Assessment
        </p>
        <p class="text-mdh text-grey-dark-1 mx-2 max-w-3/5 mb-2">
            Select individual employees or teams from the list below. The ones you select will receive an invitation to
            participate in this assessment. The invitations will be sent as soon only after the assessment setup is
            complete.
        </p>
        <LicensesBanner />
        <div class="mx-auto">
            <img src="../../../assets/img/home/img-no-employees.svg" class="mb-5 w-card mx-auto" />
            <p class="font-bold text-mdxl text-grey-dark-2 text-center mb-1">
                No Employee Data Added Yet
            </p>
            <p class="text-md text-grey-light text-center max-w-1/3 mx-auto mb-6.5">
                In order to invite participants to your assessment, please upload an up to date list of employees first.
            </p>
            <div class="flex flex-col justify-center items-center">
                <div class="w-250 mb-2">
                    <Button
                        text="Download Template File (.CSV)"
                        type="secondary"
                        class="w-full"
                        @click.native="downloadTemplate"
                        data-testid="download-button"
                    />
                </div>
                <div class="w-250 mb-2">
                    <Button
                        text="Upload Employee File (.CSV)"
                        type="primary"
                        class="w-full"
                        :onClick="upload"
                        data-testid="upload-button"
                    />
                </div>
                <div class="w-250">
                    <Button
                        text="Add Employees Individually"
                        type="primary"
                        class="w-full"
                        :onClick="addIndividualUser"
                        data-testid="add-user-button"
                    />
                </div>
            </div>
            <input
                type="file"
                ref="files"
                @change="handleFileUpload"
                class="hidden"
                :key="inputKey"
                data-testid="file-input"
            />
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import _trim from 'lodash/trim';
import LicensesBanner from './LicensesBanner.vue';

export default {
    components: { LicensesBanner },
    name: 'UserUpload',
    data: () => ({
        users: [],
        inputKey: 0,
    }),
    props: {
        refreshUsers: Function
    },
    methods: {
        ...mapActions({
            downloadTemplate: 'utils/downloadTemplate',
            setUserPreviewList: 'assessmentSetup/setUserPreviewList',
            openModal: 'modals/openModal',
            showError: 'alerts/showError',
        }),
        open() {
            this.openModal({ modal: 'usersPreview', props: { callback: this.refreshUsers } });
        },
        handleFileUpload() {
            this.$papa.parse(this.$refs.files.files[0], {
                header: true,
                encoding: "ISO-8859-1",
                complete: (results) => {
                    let errors = 0;
                    if (results.meta['fields'].indexOf('First Name') < 0) errors += 1;
                    if (results.meta['fields'].indexOf('Last Name') < 0) errors += 1;
                    if (results.meta['fields'].indexOf('Job Title') < 0) errors += 1;
                    if (results.meta['fields'].indexOf('Email') < 0) errors += 1;
                    if (errors === 0) {
                        this.users = results.data
                            .map((el) => ({
                                firstName: _trim(el['First Name']),
                                lastName: _trim(el['Last Name']),
                                email: _trim(el['Email']),
                                jobTitle: _trim(el['Job Title']),
                            }))
                            .filter((el) => el.firstName !== '' && el.lastName !== '' && el.email !== '');
                        this.setUserPreviewList({ users: this.users });
                        this.openModal({ modal: 'usersPreview', props: { callback: this.refreshUsers } });
                        this.inputKey += 1;
                    } else {
                        this.showError({
                            messageHeader: 'Upload Error',
                            message:
                                'Please check your file to make sure that it uses the same format as the template provided and upload again.',
                        });
                    }
                },
                error: async () => {
                    this.showError({
                        messageHeader: 'Upload Error',
                        message:
                            'Please check your file to make sure that it uses the same format as the template provided and upload again.',
                    });
                },
            });
        },
        addIndividualUser() {
            this.openModal({
                modal: 'individualUserModal',
                props: { withRole: this.withRole, callback: this.refreshUsers },
            });
        },
        upload() {
            this.$refs.files.click();
        },
    },
};
</script>

<style></style>
