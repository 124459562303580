<template>
  <div class="mx-2 mb-8">
    <div class="bg-blue-light-1 p-2 rounded flex">
      <div class="flex max-w-3/4 flex-wrap">
        <p class="text-mdh text-grey-dark-2">
          Your current invites agreement allows for
        </p>
        <p
          class="text-mdh text-grey-dark-2 font-bold mx-0.4"
          v-if="allowUnlimitedInvites"
        >
          unlimited participants
        </p>
        <p class="text-mdh text-grey-dark-2 font-bold mx-0.4" v-else>
          {{ licenses }} {{ licenses === 1 ? "participant" : "participants" }}
        </p>
        <p class="text-mdh text-grey-dark-2">for this assessment.</p>
        <p class="text-mdh text-grey-dark-2">
          The invites are not transferrable once the assessment invitations have
          been sent. To add more participants, please contact your account
          representative.
        </p>
        <p class="text-mdh text-grey-dark-2"></p>
      </div>
      <div class="flex flex-col flex-grow" v-if="!allowUnlimitedInvites">
        <p class="text-l-2 text-grey-dark-2 font-bold text-right">
          {{ isDraft ? licenses - participants.length : "" }}
          <span class="text-grey-light"
            >{{ isDraft ? "of" : "" }} {{ licenses }}</span
          >
        </p>
        <p class="text-mdh text-grey-dark-2 text-right">invites remaining</p>
      </div>
      <div class="flex flex-col flex-grow" v-if="allowUnlimitedInvites">
        <p class="text-l-2 text-grey-dark-2 font-bold text-right">
          Unlimited
        </p>
        <p class="text-mdh text-grey-dark-2 text-right">invites remaining</p>
      </div>
    </div>
    <p
      v-if="participantsError && isDraft"
      class="flex justify-end mt-1 text-md text-red"
    >
      Remove
      {{ participants.length - licenses }}
      participants to complete the assessment set up. Contact ISM to purchase
      additional invites.
    </p>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "LicensesBanner",
  computed: {
    ...mapState({
      participants: (state) => state.assessmentSetup.participants,
      licenses: (state) => state.invites.invitesInfo?.totalRemaining,
      allowUnlimitedInvites: (state) => state.invites.allowUnlimitedInvites,
      company: (state) => state.companies.currentCompany,
      mode: (state) => state.assessmentSetup.mode,
    }),
    participantsError() {
      return (
        !this.allowUnlimitedInvites && this.participants.length > this.licenses
      );
    },
    isDraft() {
      return this.mode === "set-up";
    },
  },
  async mounted() {
    await this.getLicenses(this.company.id);
  },
  methods: {
    ...mapActions({
      getLicenses: "invites/getOrgInvitesInfo",
    }),
  },
};
</script>
