<template>
  <div>
    <div v-if="participants.length !== 0" class="px-2">
      <div class="flex justify-between items-center mb-2">
        <p class="text-sm-2 text-grey-dark-2">
          Showing {{ mappedList.length }} out of {{ totalCount }} participants
        </p>
        <Button
          type="secondary"
          size="medium"
          text="Remove from Participants"
          :iconLeft="remove"
          @click.native="removeFromParticipants"
          :disabled="numberOfSelectedUsers === 0"
        />
      </div>
      <div>
        <div class="flex flex-row w-full bg-grey-light-3 py-1 px-2 rounded">
          <button class="mr-2" @click="toggleAll">
            <Checkbox :active="isSelectedAll" data-testid="select-all-checkbox" />
          </button>
          <div
            class="flex flex-row items-center focus:outline-none w-tableIndex"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">NO.</p>
          </div>
          <div class="flex flex-row items-center focus:outline-none">
            <p class="text-sm-2 text-grey-light">STATUS</p>
          </div>
          <div class="flex flex-grow w-1/4">
            <button
              class="flex flex-row items-center focus:outline-none"
              data-testid="header-1"
              @click="() => sort('name', 0)"
            >
              <p class="text-sm-2 text-grey-light mr-0.4">NAME</p>
              <img
                src="../../../../assets/img/delta-table/caret.svg"
                class="transition-all"
                :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
              />
            </button>
          </div>
          <div class="flex flex-grow w-1/5">
            <button
              class="flex flex-row items-center focus:outline-none"
              data-testid="header-2"
              @click="() => sort('jobTitle', 1)"
            >
              <p class="text-sm-2 text-grey-light mr-0.4">JOB TITLE</p>
              <img
                src="../../../../assets/img/delta-table/caret.svg"
                class="transition-all"
                :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
              />
            </button>
          </div>
          <div class="flex flex-grow w-1/5">
            <div
              class="flex flex-row items-center focus:outline-none"
              data-testid="header-3"
            >
              <p class="text-sm-2 text-grey-light mr-0.4">TEAM</p>
            </div>
          </div>
          <div class="flex flex-grow w-1/5">
            <button
              class="flex flex-row items-center focus:outline-none"
              data-testid="header-4"
              @click="() => sort('email', 3)"
            >
              <p class="text-sm-2 text-grey-light mr-0.4">EMAIL</p>
              <img
                src="../../../../assets/img/delta-table/caret.svg"
                class="transition-all"
                :class="rotationMatrix[3] !== 1 ? 'transform rotate-180' : null"
              />
            </button>
          </div>
        </div>
        <div>
          <VirtualList
            style="max-height: 364px; overflow-y: auto"
            :data-key="'email'"
            :data-sources="mappedList"
            :data-component="itemComponent"
            :extra-props="{ toggle: toggleItem }"
            data-testid="participants-table"
            @tobottom="triggerFetch"
            />
        </div>
      </div>
    </div>
    <div v-else class="px-2 -mt-6 text-grey-dark-1 text-mdh">
      <p>No participants added yet.</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import remove from '@/assets/img/icons/remove.svg';
import Checkbox from '../../../../components/Checkbox/';
import ListItem from './ListItem.vue';
import VirtualList from 'vue-virtual-scroll-list';
import Button from '../../../../components/Button/Button.vue';

export default {
  name: 'ParticipantsTable',
  components: { Checkbox, VirtualList, Button },
  props: {
    onParticipantsRemoved: Function
  },
  data: () => ({
    remove,
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1, 1],
    mappedList: [],
    selectedItems: new Set([]),
    lastSortingCriteria: undefined,
    sorting: null,
    pageNr: 1,
    pageSize: 20,
  }),
  computed: {
    ...mapState({
      mode: (state) => state.assessmentSetup.mode,
      id: (state) => state.assessmentSetup.cloneId,
      participants: (state) => state.assessmentSetup.participants,
      totalCount: (state) => state.assessmentSetup.participantsCount,
      shouldEdit: state => state.assessmentSetup.shouldEdit,
      company: (state) => state.companies.currentCompany,
    }),
    numberOfSelectedUsers() {
      return this.mappedList.filter((item) => item.isSelected).length;
    },
    isSelectedAll() {
      return !!this.selectedItems.size && this.mappedList
        .map(x => x.id)
        .every(id => this.selectedItems.has(id));
    }
  },
  mounted() {
    this.updateList(true);
  },
  methods: {
    ...mapActions({
      updateSurveyParticipants: 'assessmentSetup/updateSurveyParticipants',
      getParticipants: 'assessmentSetup/getParticipants',
      openModal: "modals/openModal",
      getLicenses: "invites/getOrgInvitesInfo",
      showError: "alerts/showError",
    }),
    async updateList(reset, fetchAll) {
      if (reset) {
        this.mappedList = [];
        this.pageNr = 1;
      }
      await this.getParticipants({
        id: this.id,
        pageNr: this.pageNr,
        pageSz: fetchAll ? this.totalCount : this.pageSize,
        keyword: "",
        sorting: this.sorting,
      });
      const map = this.participants.map((item) => ({
        ...item,
        isSelected: false,
        teams: item.teams.map(x => x.name).join(', ')
      }));
      this.mappedList = [...this.mappedList, ...map];
      this.updateSelectedItems();
    },
    async removeFn() {
      try {
        const idsToRemove = this.mappedList
            .filter((item) => item.isSelected)
            .map((item) => item.id)

        await this.updateSurveyParticipants({ id: this.id, users: idsToRemove, type: 1 });
        this.selectAll = false;
        await this.getLicenses(this.company.id);
        this.updateList(true);
        this.selectedItems = new Set([])
        if (this.onParticipantsRemoved) {
          this.onParticipantsRemoved()
        }
      } catch(err) {
        this.showError({
          messageHeader: "Error",
          message: err.message || 'Error removing participant. Please try again in a few moments',
        })
      }
    },
    removeFromParticipants() {
      if (this.shouldEdit) {
        this.openModal({
          modal: 'removeParticipant', props: {
            remove: this.removeFn
          }
        });
      } else {
        this.removeFn();
      }
    },
    updateSelectedItems() {
      this.selectedItems = new Set(this.selectedItems);
      this.mappedList.map(item => item.isSelected = this.selectedItems.has(item.id))
    },
    async toggleAll() {
      if (this.mappedList.length < this.totalCount) {
        await this.updateList(true, true);
      }
      if (this.selectedItems.size < this.mappedList.length) {
        this.mappedList.forEach(x => this.selectedItems.add(x.id))
      } else {
        this.selectedItems = new Set([]);
      }
      this.updateSelectedItems();
    },
    toggleItem(id) {
      if (this.selectedItems.has(id)) {
        this.selectedItems.delete(id)
      } else {
        this.selectedItems.add(id);
      }
      this.updateSelectedItems();
    },
    resetRotationMatrix(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx === index ? (item *= -1) : (item = 1)
      );
    },
    sort(key, index) {
      this.resetRotationMatrix(index);
      this.lastSortingCriteria = key;
      switch (key) {
        case "name":
          this.sorting = { firstName: this.rotationMatrix[index] == 1 ? 1 : 2 };
          break;
        case "jobTitle":
          this.sorting = { jobTitle: this.rotationMatrix[index] == 1 ? 1 : 2 };
          break;
        case "email":
          this.sorting = { email: this.rotationMatrix[index] == 1 ? 1 : 2 };
          break;
        default:
          break;
      }
      this.updateList(true);
    },
    triggerFetch() {
      if (this.pageNr < Math.ceil(this.totalCount / this.pageSize)) {
        this.pageNr++;
        this.updateList();
      }
    },
  },
};
</script>
