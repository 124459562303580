/* OUT OF USE */
<template>
  <div :data-testid="`listitem-${index}`">
    <div class="flex flex-row w-full items-center pl-2 h-50 bg-white">
      <button class="mr-2" @click="extendToggle" data-testid="toggle-checkbox">
        <Checkbox :active="source.isSelected" />
      </button>
      <div class="flex w-tableIndex justify-center mr-5 pr-3">
        <p class="font-normal text-md text-grey-dark-2">
          {{ source.index + 1 }}
        </p>
      </div>
      <div class="flex flex-grow max-w-1/2 h-full items-center">
        <p
          class="text-md text-grey-dark-1 font-semibold"
          v-clamp="$screen.breakpoint.xlAndDown ? '30' : '60'"
        >
          {{ source.name }}
        </p>
      </div>
      <div class="flex w-1/5 h-full items-center">
        <p class="text-md text-grey-dark-2" v-clamp>
          {{ managerName }}
        </p>
      </div>
      <div class="flex w-1/5 px-3">
        <p class="text-md text-grey-dark-2">
          {{ source.users.length }}
        </p>
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
import Checkbox from "../../../../components/Checkbox/";
export default {
  name: "ListItem",
  components: {
    Checkbox,
  },
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
    toggle: { type: Function, default: () => {} },
  },
  computed: {
    managerName() {
      return this.source.manager
        ? `${this.source.manager.firstName} ${this.source.manager.lastName}`
        : "No manager assigned";
    },
  },
  methods: {
    extendToggle() {
      this.toggle(this.source.index, this.source);
    },
  },
};
</script>
