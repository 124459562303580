<template>
  <div :data-testid="`listitem-${index}`">
    <div class="flex flex-row w-full items-center px-2 h-50 bg-white">
      <button
        class="mr-2"
        @click="() => toggle(source.id)"
        data-testid="toggle-checkbox"
      >
        <Checkbox :active="source.isSelected" />
      </button>
      <div class="flex w-tableIndex justify-center pr-3">
        <p class="font-normal text-md text-grey-dark-2">
          {{ index + 1 }}
        </p>
      </div>
      <div class="w-42 flex justify-center mr-0.5">
        <div class="w-10 h-10 rounded-full" :class="statusColor" />
      </div>
      <div class="flex flex-grow w-1/4">
        <p class="text-md text-grey-dark-1 font-semibold" v-clamp="30">
          {{ fullName }}
        </p>
      </div>
      <div class="flex flex-grow w-1/5">
        <p class="text-md text-grey-dark-2" v-clamp="30">
          {{ source.jobTitle }}
        </p>
      </div>
      <div class="flex flex-grow w-1/5">
        <p class="text-md text-grey-dark-2" v-clamp="30">
          {{ source.teams }}
        </p>
      </div>
      <div class="flex flex-grow w-1/5">
        <p class="text-md text-grey-dark-2" v-clamp="30">
          {{ source.email }}
        </p>
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Checkbox from '../../../../components/Checkbox/';
export default {
  name: 'ListItem',
  components: {
    Checkbox,
  },
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
    toggle: { type: Function, default: () => {} },
  },
  computed: {
    ...mapState({
      mode: (state) => state.assessmentSetup.mode,
    }),
    fullName() {
      return this.source.firstName + ' ' + this.source.lastName;
    },
    statusColor() {
      switch (this.source.status) {
        case '1':
          return 'bg-green-2';
        case '2':
          return 'bg-yellow-medium';
        case '3':
          return 'bg-grey-medium-2';
        default:
          break;
      }
    },
  },
};
</script>
