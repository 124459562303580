/* OUT OF USE */
<template>
  <div>
    <p class="text-sm-2 text-grey-dark-2 mb-2">
      Showing {{ mappedList.length }} Teams
    </p>
    <div>
      <div
        class="flex flex-row w-full bg-grey-light-3 py-1 pl-2 rounded"
        :class="mappedList.length > 7 ? 'pr-2' : ''"
      >
        <button class="mr-2" @click="toggleAll">
          <Checkbox :active="selectAll" data-testid="select-all-checkbox" />
        </button>
        <div
          class="flex flex-row items-center focus:outline-none w-tableIndex mr-5"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">NO.</p>
        </div>
        <div class="flex flex-grow max-w-1/2">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('name', 0)"
            data-testid="header-1"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">NAME</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex w-1/5">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('manager', 1)"
            data-testid="header-2"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">MANAGER</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex w-1/5">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('users', 2)"
            data-testid="header-3"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">MEMBERS</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
      </div>
      <div>
        <VirtualList
          style="overflow-y: auto"
          :style="
            teams.length > 7
              ? { height: '364px' }
              : { height: `${52 * teams.length}px` }
          "
          :data-key="'name'"
          :data-sources="mappedList"
          :data-component="itemComponent"
          :extra-props="{ toggle: toggleItem }"
          data-testid="participants-teams-table"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Checkbox from "../../../../components/Checkbox/";
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";

export default {
  name: "SetupTeamsTable",
  components: { Checkbox, VirtualList },
  data: () => ({
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1],
    mappedList: [],
    selectAll: false,
  }),
  props: { handler: { type: Function } },
  computed: {
    ...mapState({
      teams: (state) => state.assessmentSetup.teams,
    }),
  },
  watch: {
    teams(val) {
      let map = val.map((item, idx) => ({
        ...item,
        index: idx,
        isSelected: false,
      }));
      this.mappedList = [...map];
    },
  },
  mounted() {
    let map = this.teams.map((item, idx) => ({
      ...item,
      index: idx,
      isSelected: false,
    }));
    this.mappedList = [...map];
  },
  methods: {
    ...mapActions({
      bulkToggleParticipants: "assessmentSetup/bulkToggleParticipants",
    }),
    setRotation(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx == index ? -1 * item : 1
      );
    },
    sort(col, index) {
      this.rotationMatrix.map((_, idx) => {
        if (idx !== index) {
          this.rotationMatrix[idx] = 1;
        } else this.rotationMatrix[idx] *= -1;
      });
      switch (col) {
        case "name":
          this.mappedList.sort((a, b) =>
            this.rotationMatrix[index] == 1 ? a.name > b.name : a.name < b.name
          );
          break;
        case "manager":
          this.mappedList.sort((a, b) => {
            if (!a.manager) return false;
            if (!b.manager) return false;
            return this.rotationMatrix[index] == 1
              ? a.manager.firstName > b.manager.firstName
              : a.manager.firstName < b.manager.firstName;
          });
          break;
        case "users":
          this.mappedList.sort((a, b) =>
            this.rotationMatrix[index] == 1
              ? a.users.length > b.users.length
              : a.users.length < b.users.length
          );
          break;
        default:
          break;
      }
    },
    async toggleItem(index, team) {
      this.mappedList[index].isSelected = !this.mappedList[index].isSelected;
      const validation = await this.bulkToggleParticipants({
        toggleState: this.mappedList[index].isSelected,
        listToLookup: team.users,
      });
      if (!validation) {
        this.mappedList[index].isSelected = !this.mappedList[index].isSelected;
      }
    },
    async toggleAll() {
      this.selectAll = !this.selectAll;
      for (let i = 0; i < this.mappedList.length; i++) {
        const team = this.mappedList[i];
        team.isSelected = this.selectAll;
        const validation = await this.bulkToggleParticipants({
          toggleState: team.isSelected,
          listToLookup: team.users,
        });
        if (!validation) {
          team.isSelected = !team.isSelected;
          this.selectAll = !this.selectAll;
          break;
        }
      }
    },
  },
};
</script>
